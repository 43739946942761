<template lang="pug">
transition(name="fade")
  .search(v-show="open" @mousedown.left.self="close" @keydown.esc="close")
    form-input.search--input(
      v-model="valueComputed"
      ref="searchinput"
      icon-right="fa-times"
      :classInput="{ 'gtm-internal-search': true }"
      @keyup.esc.native="close"
      @keyup.enter.native="navigateSearch(true)"
      @right-icon-click="empty"
    )
    transition(name="fade")
      .results(v-if="value")
        .tabs
          .tab(
            v-for="(tab, tabIndex) in tabs"
            :class="{ 'active': activeTab === tabIndex, 'empty': !tab.count }"
            @click="activeTab = tabIndex"
          )
              | {{ tab.label }} ({{ tab.count }})
        .body(v-if="activeTab === 0 && productsStats.hits")
          .body__items
            router-link.result-item(
              v-for="product in productsStats.hits"
              v-html="product._highlightResult.name.value"
              :key="product.path"
              :to="`/${product.path}`"
            )
          .body__all
            router-link.more-item(:to="`/producten/zoekresultaten/${this.valueComputed}`")
              span Meer producten
              i.far.fa-angle-right.more-item--icon
        .body(v-if="activeTab === 1 && (mixMatchesStats.nbHits || 0) + (productOffersStats.nbHits || 0)" )
          .body__items
            router-link.result-item(
              v-for="mixMatch in mixMatchesStats.hits"
              v-html="mixMatch._highlightResult.shop_name.value"
              :key="mixMatch.id"
              :to="mixMatch.slug ? `/aanbiedingen/${mixMatch.slug}` : ''"
            )
            router-link.result-item(
              v-for="productOffer in productOffersStats.hits"
              v-html="productOffer._highlightResult.name.value"
              :key="productOffer.path"
              :to="`/${productOffer.path}`"
            )
            .body__all
              router-link.more-item(:to="`/aanbiedingen?search=${valueComputed}`")
                span Meer aanbiedingen
                i.far.fa-angle-right.more-item--icon
        .body(v-if="activeTab === 2 && recipesStats.hits")
          .body__items
            router-link.result-item(
              v-for="recipe in recipesStats.hits"
              v-html="recipe._highlightResult.name.value"
              :key="recipe.slug"
              :to="`/recepten/${recipe.slug}`"
            )
          .body__all
            router-link.more-item(:to="`/recepten?page=0&search=${this.valueComputed}`")
              span Meer recepten
              i.far.fa-angle-right.more-item--icon
        .body(v-if="activeTab === 3 && farmerStats.hits" )
          .body__items
            router-link.result-item(
              v-for="supplier in farmerStats.hits"
              v-html="supplier._highlightResult.name.value"
              :key="supplier.id"
              :to="supplier.slug ? `/boeren/${supplier.slug}` : ''"
            )
          .body__all
            router-link.more-item(:to="`/boeren`")
              span Meer boeren
              i.far.fa-angle-right.more-item--icon
</template>

<script>
import { breakpointMixin } from '@/mixins/breakpointMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'SearchResults',
  components: {
    'form-input': () => import('@/components/form/formInput'),
  },
  mixins: [breakpointMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 0,
      searchTimeout: null,
    };
  },
  computed: {
    ...mapGetters({
      productsStats: 'algolia/productsStats',
      recipesStats: 'algolia/recipesStats',
      farmerStats: 'algolia/farmerStats',
      productOffersStats: 'algolia/productOffersStats',
      searchValue: 'shared/searchValue',
    }),
    tabs() {
      return [
        {
          type: 'product', label: 'Producten', count: this.productsStats.nbHits || 0, more: '/producten/zoekresultaten',
        },
        {
          type: 'mixMatch', label: 'Aanbiedingen', count: (this.mixMatchesStats.hits?.length || 0) + (this.productOffersStats.nbHits || 0), more: '/aanbiedingen',
        },
        {
          type: 'recipe', label: 'Recepten', count: this.recipesStats.nbHits || 0, more: '/recepten',
        },
        {
          type: 'farmer', label: 'Boeren', count: this.farmerStats.nbHits || 0, more: '/boeren',
        },
      ];
    },
    valueComputed: {
      get() {
        return this.searchValue;
      },
      set(value) {
        this.$emit('input', value);
        this.$store.commit('shared/setSearchValue', value);
      },
    },
    mixMatchesStats() {
      const mixMatchesStats = this.$store.getters['algolia/mixMatchesStats'];

      return {
        ...mixMatchesStats,
        hits: mixMatchesStats.hits?.filter((mixMatch) => {
          const inDeliveryArea = this.$auth.user?.cart?.data ? mixMatch.delivery_areas.includes(this.$auth.user.cart.data.delivery_area_id) : true;
          const dateFilter = this.$moment().isBetween(this.$moment(mixMatch.valid_from), this.$moment(mixMatch.valid_until));
          const visibleInShopFilter = this.$moment().isBetween(this.$moment(mixMatch.visible_in_shop_from), this.$moment(mixMatch.visible_in_shop_until));

          return inDeliveryArea && dateFilter && visibleInShopFilter;
        }),
      }
    },
  },
  watch: {
    $route() {
      this.empty();
      this.activeTab = 0;
    },
    open(value) {
      if (value) {
        this.$nextTick(() => this.$refs.searchinput.$refs.forminput.focus());
      }
    },
    value(value) {
      if (!value) {
        return false;
      }

      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
      }

      this.searchTimeout = setTimeout(async () => {
        const searchProduct = this.$store.dispatch('algolia/searchProduct', { hitsPerPage: 8, searchValue: value });
        const searchRecipe = this.$store.dispatch('algolia/searchRecipe', { hitsPerPage: 8, searchValue: value });
        const getFarmers = this.$store.dispatch('algolia/getFarmers', { hitsPerPage: 8, query: value });
        const getMixMatches = this.$store.dispatch('algolia/getMixMatches', {
          hitsPerPage: 8,
          query: value,
        });
        const getProductOffers = this.$store.dispatch('algolia/getProductOffers', { hitsPerPage: 8, query: value });

        await Promise.all([searchProduct, searchRecipe, getFarmers, getMixMatches, getProductOffers]);

        if (!this.tabs[this.activeTab]?.count) {
          this.activeTab = 0;
        }
      }, 500);

      return true;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    handleScroll() {
      if (['xs', 'sm'].includes(this.$mq)) {
        return false;
      } if (this.open) {
        this.close();
      }
      return true;
    },
    empty() {
      this.$emit('empty', false);
    },
    doSearchNavigation() {
      const { type, more } = this.tabs[this.activeTab];
      let url = more;

      if (type === 'product') {
        url = `${more}/${this.valueComputed}`;
      }

      if (this.$route.name === 'producten-zoekresultaten-query') this.$router.replace(url);
      else this.$router.push(url);
    },
    navigateSearch(close = false) {
      // if (this.$router.history.current.path === this.tabs[this.activeTab].more) return;
      if (this.valueComputed) this.doSearchNavigation();
      if (!this.valueComputed) this.$router.back();
      if (close) this.close();
    },
  },
};
</script>

<style lang="scss">
.search {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  padding: 15px 0 0 0;
  background: rgba($black, .5);

  &--input {
    width: 100%;
    max-width: 400px;
    height: 48px;

    .form-input--icon {
      font-weight: 100;
    }
  }

  .results {
    position: absolute;
    top: 80px;
    width: 100%;
    max-width: 600px;
    padding: 40px 30px;
    border-radius: $radius;
    box-sizing: border-box;
    background: $white;
  }

  .tabs {
    display: flex;
    padding: 0 20px 30px 20px;
    box-sizing: border-box;
  }

  .tab {
    padding: 0 40px 0 0;
    color: $quickSilver;
    font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      transition: $transition;

    &.active {
      color: $hunterGreen;
    }

    &.empty {
      display: none;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
  }

  .body__items {
    display: flex;
    flex-direction: column;
  }

  .result-item {
    padding: 12px 20px;
    border-radius: $radius-small;
    font-weight: 600;
    font-size: 15px;

    em {
      font-weight: 400;
      font-style: normal;
    }
  }

  .more-item {
    display: flex;
    align-items: center;
    margin: 18px 0 0 0;
    padding: 12px 20px;
    color: $hunterGreen;
    font-size: 15px;

    &--icon {
      margin: 0 15px;
      color: $blackOlive;
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 1024px) {
    // hover styles only on desktop

    .result-item:hover {
      background: $whiteSmoke;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    .body {
      &__items {
        overflow: scroll;
        height: 200px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    width: auto;
    padding: 0;

    &--input {
      z-index: 1;
      max-width: unset;
      height: 60px;
      border-bottom: 1px solid $alabaster;

      input {
        height: 60px !important;
        max-height: 60px !important;
        max-height: unset;
        padding: 15px 20px;
        border: none;
        border-radius: 0;

        &:focus {
          border: none;
        }
      }

      .form-input--icon {
        top: 8px;
        padding: 15px 20px;
      }
    }

    .results {
      top: 60px;
      max-width: unset;
      padding: 20px 0 40px 0;
      border-radius: 0;
    }

    .result-item {
      font-size: 14px;
    }

    .tabs {
      overflow-x: auto;
      padding: 20px;
      white-space: nowrap;
    }

    .tab {
      padding: 0 30px 0 0;
      font-size: 16px;
    }
  }
}
</style>
